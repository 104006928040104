import Router from 'vue-router';
import { storeToRefs } from 'pinia';

import AgentRoutes from '@/router/AgentRoutes';
import EditorRoutes from '@/router/EditorRoutes';
import OverviewRoutes from '@/router/OverviewRoutes';

import useUserStore from '@/stores/user';

import useNavigationStore from '@/stores/navigation';
import useConfigStore from '@/stores/site/config';

const baseRoutes = [
	{
		path: '/',
		name: 'index',
		meta: { auth: true },

		redirect: () => {
			const userStore = useUserStore();
			const { user } = storeToRefs(userStore);
			if (user.value?.user_type) {
				switch (user.value?.user_type) {
					case 'mirus':
						return {
							name: 'mirus',
							params: { userID: user.value.user?.replace(/@[\d\D]*/, '') },
						};

					case 'staff':
						return { name: 'atms', params: { staffID: user.value.user_associate_id } };
					case 'corp':
						return {
							name: 'employees',
							params: { employeeID: user.value.user_associate_id },
						};
					case 'agent':
						return {
							name: 'dashboard',
							params: { agentID: user.value.user_associate_id },
						};

					case 'unknown':
						return { name: 'user', params: { userID: user.value.user_details.email } };

					default:
						break;
				}
			}

			return `/login`;
		},
	},
	{
		path: '/user/:userID/',
		name: 'user',
		component: () => import('@/views/Landing/UserLanding'),
		meta: {
			auth: true,
		},
	},
	{
		path: '/mirus/:userID/',
		name: 'mirus',
		component: () => import('@/views/Landing/MirusLanding'),
		meta: {
			auth: true,
		},
	},
	{
		path: '/atms/:staffID/',
		name: 'atms',
		components: { default: () => import('@/views/Landing/StaffLanding') },
		meta: {
			auth: true,
		},
	},
	{
		path: '/employees/:employeeID/',
		name: 'employees',
		components: { default: () => import('@/views/Landing/CorporateLanding') },
		meta: {
			auth: true,
		},
	},
	{
		path: '/agents/:agentID/',
		components: {
			default: () => import('@/views/Agents/AgentViews'),
			navigation: () => import('@/components/navigation/AgentNavigation'),
		},
		props: { default: true, navigation: false },
		beforeEnter: handleAgentEnter,
		meta: {
			auth: true,
			navigation: true,
		},
		children: AgentRoutes,
	},

	{
		path: '/agents/:agentID/domains/:domainName/',
		components: {
			default: () => import('@/views/MXEditor/MXEditor'),
			navigation: () => import('@/components/navigation/EditorNavigation'),
		},
		props: { default: true, navigation: false },
		beforeEnter: (to, from, next) => {
			const userStore = useUserStore();
			const configStore = useConfigStore();
			const { user } = storeToRefs(userStore);

			const demoDomainFound = configStore.demoDomains.find(d => d === to.params.domainName);
			const demoUserFound = configStore.demoUsers.find(
				u => u.associate_id === user.value.user_associate_id
			);

			if (user?.user_type === 'mirus' || (demoUserFound && demoDomainFound)) {
				next();
			} else {
				handleAgentEnter(to, from, next);
			}
		},
		children: EditorRoutes,
		meta: {
			title: 'Edit Domain',
			auth: true,
			navigation: true,
		},
	},

	{ path: '/login/', name: 'login', component: () => import('@/views/Authentication/TheLogin') },
	{
		path: '/logout/',
		name: 'logout',
		component: () => import('@/views/Authentication/TheLogout'),
	},
	{
		path: '/forbidden/',
		name: 'forbidden',
		component: () => import('@/views/Authentication/AccessDenied'),
	},
	{ path: '/m1-video/', name: 'm1-video', component: () => import('@/views/M1Video') },
	{ path: '/m2-video/', name: 'm2-video', component: () => import('@/views/M2Video') },
	{
		path: '/overview/',
		name: 'overview',

		components: {
			default: () => import('@/views/Overview/OverviewViews'),
			navigation: () => import('@/components/navigation/OverviewNavigation'),
		},
		children: OverviewRoutes,
		meta: {
			auth: true,
			navigation: true,
		},
	},
	{
		path: '/m1auth/*',
		meta: {
			auth: true,
		},
		beforeEnter(to) {
			window.location.href = `http://mysfdomain.com${to.path}`;
		},
	},
	{
		path: '/glossary/',
		name: 'glossary',
		meta: {
			auth: true,
		},
		component: () => import('@/views/GlossaryList'),
	},
	{
		path: '/glossary/:keyword/',
		meta: {
			auth: true,
		},
		component: () => import('@/views/GlossaryDetail'),
	},

	{ path: '/*', component: () => import('@/views/FourOhFour') },
];

function prepareRoutes(array) {
	const finalArray = [];
	array.forEach(route => {
		const newRoute = { ...route };
		const { children } = route;
		newRoute.pathToRegexpOptions = { strict: true };

		if (children) {
			newRoute.children = prepareRoutes(children);
		}
		finalArray.push(newRoute);
		const hasTrailingSlash = newRoute.path.match(/\/\*?$/);
		if (
			!(hasTrailingSlash || newRoute.path === '') &&
			newRoute.path !== '*' &&
			process.env.NODE_ENV === 'development'
		) {
			alert(
				`The route "${newRoute.path}" should have a trailing slash! Please check the console for details and update the route in baseRoutes`
			);
			console.warn(newRoute);
		} else {
			finalArray.push({ path: newRoute.path.replace(/\/*?$/, ''), redirect: newRoute.path });
		}
		return newRoute;
	});
	return finalArray;
}
export const routes = prepareRoutes(baseRoutes);
const router = new Router({
	mode: 'history',
	linkActiveClass: 'linkActive',
	linkExactActiveClass: 'linkExactActive',

	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { el: '#main' };
		}
	},
	routes,
});

router.beforeEach(async (to, from, next) => {
	const navigationStore = useNavigationStore();

	navigationStore.showNavigation = to.matched.some(route => route.meta.navigation);

	const userStore = useUserStore();

	// update page title based on route
	if (to.meta?.title) {
		switch (typeof to.meta?.title) {
			case 'string':
				document.title = to.meta.title;
				break;
			case 'function':
				document.title = to.meta.title(to);
				break;
			default:
				document.title = 'MySFdomain';
		}
	}

	// check whether any level of the route is for Mirus users only
	const mirusOnly = to.matched.some(route => route.meta.mirusOnly);

	if (mirusOnly && !userStore.isMirusUser) {
		localStorage.setItem('accessDeniedPath', to.path);
		//todo: update this to a return statement with vue router 4.x+
		next({ name: 'forbidden' });
	}

	// check whether any level of the route requres authentication && make sure user is authenticated if needed
	const authRequired = to.matched.some(route => route.meta.auth);

	if (authRequired && userStore.user === null) {
		await userStore.getUserData();
	}

	if (authRequired && userStore.user?.authenticated !== true) {
		localStorage.setItem('nextPath', to.path);
		//todo: update this to a return statement with vue router 4.x+
		next({ name: 'login' });
	}

	// if we made it this far it's safe to continue
	// this won't be necessary with vue router 4.x+,
	// as returning nothing will continue to the route
	next();
});

export default router;

async function getAllowedPages(to) {
	const userStore = useUserStore();

	let isAllowed = false;
	switch (userStore.user?.user_type) {
		case 'mirus':
			// 1. `"mirus"`: can access all pages
			isAllowed = true;
			break;
		case 'agent':
			// 2. `"agent"`:  can only access `/agent/<associate_id>/` pages for `user_associate_id`
			isAllowed = to.path.indexOf(`agents/${userStore.user.user_associate_id}`) > -1;

			break;
		case 'staff':
			/*
		3. `"staff"`: can access any `/agent/<associate_id>/` page when associate_id is in its
		  `staff_agents` list, but no other pages (aside from the atm landing page).
	  */
			isAllowed =
				to.path.indexOf(`atms/${userStore.user.user_associate_id}`) > -1 ||
				(to.path.indexOf('agents/') > -1 &&
					userStore.user.staff_agents.includes(to.params.agentID));
			break;
		case 'corp':
			/*
		4. `"corp"`:  can only access `/overview/` and `/overview/<sub page>/`
		(when sub page is listed in `corp_permissions`), along with the corp landing page.
		(<Overview component will handle routing permissions for subpages)
	  */
			isAllowed =
				to.path.indexOf(`employees/${userStore.user.user_associate_id}`) > -1 ||
				to.path.indexOf(`/overview`) === 0;
			break;
		case 'unknown':
			isAllowed =
				to.path.indexOf(`userStore.user.user/${userStore.user.user_details.email}`) > -1;
			break;
		default:
			isAllowed = false;
	}

	if (isAllowed) {
		return Promise.resolve();
	} else {
		return Promise.reject();
	}
}

async function handleAgentEnter(to, from, next) {
	getAllowedPages(to)
		.then(next)
		.catch(() => {
			localStorage.setItem('accessDeniedPath', to.path);
			next('/forbidden');
		});
}
